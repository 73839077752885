import { Component, HostBinding, computed, input } from '@angular/core';
import { Color, Size } from '../type';

@Component({
  selector: 'app-icon',
  standalone: true,
  template: '',
  styleUrl: './icon.component.scss',
})
export class IconComponent {
  name = input.required<string>();

  _name = computed(() => encodeURI(`https://api.iconify.design/${this.name()}.svg`));

  size = input<Size>('md');
  color = input<Color>();

  style = computed(() => ({
    maskSize: 'cover',
    maskImage: `url(${this._name()})`,
    webkitMaskSize: 'cover',
    webkitMaskImage: `url(${this._name()})`,
    display: 'inline-block',
    background: 'currentColor',
  }));

  class = computed(() => `${this.size() ? `${this.size()}` : ''}  ${this.color() ? this.color() : ''}`);

  @HostBinding('style')
  get hostStyle() {
    return this.style();
  }

  @HostBinding('class') get hostClass() {
    return this.class();
  }
}
